import { API } from '../api';

const getById = (id, view = null) => API.get('client/appointment/appointment/get-appointment', { id: id, view: view })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.appointment;
        }

        throw response.data;
    });

const getByIds = (ids, view = null) => API.get('client/appointment/appointment/get', { id: ids, view: view })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.appointments;
        }

        throw response.data;
    });

const getByRepeatId = (data) => API.get('client/appointment/appointment/get-repeated-appointments', data)
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.appointments;
        }

        throw response.data;
    });

const getByPatient = (patientId, groupId, dateType = 'upoming', repeated = false) => API.get('client/appointment/appointment/get-by-patient', { patientId: patientId, dateType: dateType, repeated: repeated, groupId: groupId })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.appointments;
        }

        throw response.data;
    });

const quickUpdate = (id, data) => API.post('client/appointment/appointment/quick-update', { id: id, data: data })
    .then(response => {
        if (response.status === 'success') {
            return response.data;
        }

        throw response.data[Object.keys(response.data)[0]];
    });

const quickUpdateOpenAppointment = (id, data) => API.post('client/appointment/appointment/quick-update-open', { id: id, data: data })
    .then(response => {
        if (response.status === 'success') {
            return response.data;
        }

        throw response.data[Object.keys(response.data)[0]];
    });

const moveAppointment = (appointmentId, data) => API.post('client/appointment/appointment/move', { id: appointmentId, data: data })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }

        throw response.data[Object.keys(response.data)[0]];
    });

const search = (searchStr, type, groupId) => API.get('client/appointment/appointment/search', { searchStr: searchStr, dateType: type, groupId: groupId })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.appointments;
        }

        throw response.data;
    });

const removePatientUpcomingAppointments = (patientId) => API.remove('client/appointment/appointment/remove-patient-upcoming-appointments', { patientId: patientId })
    .then(response => {
        if (API.isSuccess(response)) {
            return true;
        }

        throw response.data;
    });

const getStats = (groupId) => API.post('client/appointment/appointment/get-stats', { data: { groupId: groupId } })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }

        throw response.data[Object.keys(response.data)[0]];
    });

const getLogsByAppointment = (appointmentId) => API.get('client/appointment/appointment/get-logs-by-appointment', { id: appointmentId })
    .then(response => {
        if (response.status === 'success') {
            return response.data;
        }

        return false;
    });

const searchCode = (type, data) => API.get('client/appointment/appointment/search-code/' + type, data)
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }
        return {};
    });

const saveRepeated = (data) => API.post('client/appointment/appointment/create-appointment-repeated', { data: data })
    .then(response => {
        if (response.status === 'success') {
            return response.data;
        }

        return false;
    });

const sendNotification = (data) => API.post('client/appointment/appointment/send-notification', { data: data }).then(response => {
    if (API.isSuccess(response)) {
        return true;
    }
    throw new Error('error');
});

export const ApiAppointmentService = {
    getById,
    getByIds,
    getByRepeatId,
    getByPatient,
    quickUpdate,
    quickUpdateOpenAppointment,
    moveAppointment,
    search,
    removePatientUpcomingAppointments,
    getStats,
    getLogsByAppointment,
    searchCode,
    saveRepeated,
    sendNotification
};